<ctrm-grid (onClickAdd)="onClickAddfx($event)" (onSave)="onSave($event)" [breadcrumbMapKey]="commonService.RISK_MAP_KEY"
           [currentRoute]="'/fxPrice'" [grid]="grid" [noDataTemplate]="noData"
           [refreshGrid]="refreshGrid" [saveOnSave]="false"></ctrm-grid>

<p-sidebar *ngIf="display" [(visible)]="display" [baseZIndex]="5"
           [ngStyle]="{maxHeight: fxHeight}"
           [showCloseIcon]="false" [style]="{minWidth:'50%',maxWidth:'70%',height:'100%', marginTop:'61px'}" autoLayout="true"
           class="style-4 table-responsive" position="right">

  <div class="headerClass">
    New FX Price
    <i (click)="closefx()" class="material-icons closable">clear</i>
  </div>
  <div class="priceAdding style-4">
    <div style="display: inline-flex; padding-bottom: 12px;">
      <div class="fieldBox">
        <p class="fieldName">Price Index</p>
        <p-dropdown (ngModelChange)="onChangefxIndex($event)"
                    [(ngModel)]="fxIndex"
                    [disabled]="readOnly" [id]="'futureIndex'"
                    [options]="fxIndexList"
                    appendTo="body"
                    pTooltip="{{getTooltipMessage(formControlAIO,field)}}" tooltipEvent="focus" tooltipPosition="bottom"
                    tooltipZIndex="1000"></p-dropdown>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Price Date</p>
        <div class="inputbox">
          <input [(ngModel)]="date" [bsConfig]="{dateInputFormat:getDateFormat(),adaptivePosition:true}"
                 bsDatepicker class="form-control form-control-sm pickerInput"
                 autocomplete="off"
                 placeholder="Select Date"
                 type="text">
        </div>
      </div>
      <div class="fieldBox">
        <p class="fieldName">Term Structure</p>
        <p-dropdown [(ngModel)]="termStructure"
                    [disabled]="readOnly"
                    [id]="'termStructure'" [options]="termStructureList"
                    appendTo="body"
                    pTooltip="{{getTooltipMessage(formControlAIO,field)}}"
                    tooltipEvent="focus" tooltipPosition="bottom" tooltipZIndex="1000"></p-dropdown>
      </div>
      <div>
        <button
          (click)="addPrice()"
          [disabled]="fxIndex === undefined || fxIndex === null || fxIndex.length === 0 || termStructure === undefined || termStructure === null || termStructure.length === 0 || date === undefined || date === null || date.length === 0" class="addbtn">Add Price
        </button>
      </div>
      <div>
        <button (click)="onImportClick(true)" *ngIf="grid.getGridConfig().canImport" class="importbtn">Import</button>
      </div>
    </div>


    <app-ctrm-price-table (onAddNewRowComplete)="onNewRowAddComplete()" (onImportFileComplete)="onImportComplete()" (onRefreshFormGroupComplete)="onRefreshFromGroupComplete()"
                          [columns]="gridColumns"
                          [combinationColumns]="['priceIndexCode','termStructure','priceDate']" [fileToImport]="file"
                          [gridDisplay]="gridDisplay" [newRows]="newRows"
                          [refreshFormGroup]="refreshFormGroup"
                          [refreshNewRows]="refreshAddNewRow" [value]="values"></app-ctrm-price-table>

  </div>

  <p-footer>
    <div class="footerClass saveSection">
      <div>
        <button (click)="closefx()" class="cancelBtn" label="Cancel" pButton type="button"></button>
        <button (click)="savefx(values)" [disabled]="values === undefined || values === null || values.length === 0"
                class="save">Save Price
        </button>
      </div>

    </div>
  </p-footer>

</p-sidebar>


<ng-template #noData>
  <div (inViewportAction)="calculateLeft()" [id]="'noDataBoxId'" [style]="{left:noDataBoxLeft}" class="noDataBox"
       inViewport>
    <img [src]="commonService.getIconPath('emptyStateIcon')" class="iconClass"/>
    <p class="noDataHeader">{{showFetching || isError ? loadMessage : noDataLabel}}</p>
    <div class="buttonsBox">
      <div (click)="onClickAddfx($event)" *ngIf="grid.getGridConfig().canAdd" class="noDataButton">
        <p class="noDataButtonLabel">{{'Add ' + grid.getTitle()}}</p>
      </div>
      <div (click)="onImportClick()" class="noDataButton">
        <p class="noDataButtonLabel">Import</p>
      </div>
    </div>
  </div>
</ng-template>

<app-import-grid (onClickImport)="onImport($event)" (onClose)="closeImport()" (onImport)="importDone($event)"
                 [directSave]="false" [display]="displayImport" [gridColumns]="gridColumns"
                 [importTableName]="importTableName" [parentKeyValue]="parentKeyValue"></app-import-grid>

<!--Toaster Message-->
<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
         appendTo="body">
</p-toast>

<p-confirmDialog [baseZIndex]="25" header="Confirmation"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

